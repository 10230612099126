export function getAccuracyAmountSum(arr: string | any[],decimal: number) {
 
  const precision = Math.pow(10, decimal);

  let total = 0;
  for (let i = 0; i < arr.length; i++) {
      total += Math.round(parseFloat(arr[i]) * precision);
  }

  // 将结果除以精度因子，恢复为小数
  return total / precision;
}