import { useState } from "react";
import { Address } from "ton";
import { Box, Fade, useMediaQuery } from "@mui/material";
import { jettonDeployController, JettonDeployParams } from "lib/deploy-controller";
import { checkImageURL, checkDecimals } from "helpers";

import WalletConnection from "services/wallet-connection";
import { createDeployParams } from "lib/utils";
import { ContractDeployer } from "lib/contract-deployer";
import { Link as ReactRouterLink } from "react-router-dom";
import { ROUTES } from "consts";
import useNotification from "hooks/useNotification";
import {
  FormWrapper,
  ScreenHeading,
  StyledDescription,
  StyledTxLoaderContent,
  SubHeadingWrapper,
  SubHeadingWrapper1,
} from "./styles";
import { useTranslation } from 'react-i18next';

import { Screen, ScreenContent } from "components/Screen";
import analytics, { AnalyticsAction, AnalyticsCategory } from "services/analytics";
import { getUrlParam, toDecimalsBN } from "utils";
// import { offchainFormSpec, onchainFormSpec } from "./data";
import { Form } from "components/form";
import { GithubButton } from "pages/deployer/githubButton";
import { useNavigatePreserveQuery } from "lib/hooks/useNavigatePreserveQuery";
import { useTonAddress, useTonConnectUI } from "@tonconnect/ui-react";
import Notification2 from "components/Notification/Notification2";
import Iframe from "./Iframe";
// import { t } from "i18next";

const DEFAULT_DECIMALS = 9;

const isOffchainInternal = getUrlParam("offchainINTERNAL") !== null;



async function fetchDecimalsOffchain(url: string): Promise<{ decimals?: string }> {
  let res = await fetch(url);
  let obj = await res.json();
  return obj;
}

function DeployerPage() {
  const { t } = useTranslation();
  const matches1 = useMediaQuery("(max-width:900px)");
  const onchainFormSpec = [
    {
      name: "name",
      label: t("Jetton Name"),
      description: t("Your project unabbreviated name with spaces (usually 1-3 words)."),
      type: "text",
      default: "Bitcoin Cash",
      required: true,
      errorMessage: "Name required",
    },
    {
      name: "symbol",
      label: t("Jetton Symbol"),
      description: t("Currency symbol appearing in balance (usually 3-5 uppercase chars)."),
      type: "text",
      default: "BCH",
      required: true,
      errorMessage: "Symbol required",
    },
    {
      name: "decimals",
      label: t("Jetton Decimals"),
      description: t("The decimal precision of your token (9 is TON default)."),
      type: "number",
      validate: checkDecimals,
      default: 9,
      showDefault: true,
      required: true,
      errorMessage: "Decimals amount from 0 to 255 is required", // https://github.com/ton-blockchain/TEPs/blob/master/text/0064-token-data-standard.md#jetton-metadata-attributes
    },
    {
      name: "mintAmount",
      label: t("Tokens to Mint"),
      description: t("Number of initial tokens to mint and send to your wallet address (float)."),
      type: "number",
      default: 21000000,
      required: true,
      errorMessage: "Mint amount required",
    },
    {
      name: "description",
      label: t("Description"),
      description: t("Optional sentence explaining about your project."),
      default: "Low fee peer-to-peer electronic cash alternative to Bitcoin",
    },

    {
      name: "tokenImage",
      label: t("Jetton Logo URL"),
      description: t("URL of 256x256 pixel PNG image of token logo with transparent background."),
      type: "string",
      required: false,
      validate: checkImageURL,
      default: "https://bitcoincash-example.github.io/website/logo.png",
    },
  ];
  const offchainFormSpec = [
    {
      name: "offchainUri",
      label: "Offchain URI",
      description: "JSON containing metadata. Don't forget to pin it if it's ipfs",
      type: "string",
      default: "",
      required: true,
      errorMessage: "URI required",
    },
    {
      name: "mintAmount",
      label: "Amount to Mint",
      description: "Number of initial tokens to mint and send to your wallet address (float)",
      type: "number",
      default: 21000000,
      required: true,
      errorMessage: "Mint amount required",
      disabled: undefined,
    },
  ];
  let formSpec = isOffchainInternal ? offchainFormSpec : onchainFormSpec;
  const { showNotification } = useNotification();
  const walletAddress = useTonAddress();
  const [tonconnect] = useTonConnectUI();
  const [isLoading, setIsLoading] = useState(false);
  const [conAddress, setConAddress] = useState("");
  const navigate = useNavigatePreserveQuery();

  async function deployContract(data: any) {
    if (!walletAddress || !tonconnect) {
      throw new Error("Wallet not connected");
    }
    console.log(data, 'data')
    let decimals = data.decimals;
    if (data.offchainUri) {
      let res = await fetchDecimalsOffchain(
        data.offchainUri.replace("ipfs://", "https://ipfs.io/ipfs/"),
      );
      decimals = res.decimals;
    }

    const params: JettonDeployParams = {
      owner: Address.parse(walletAddress),
      onchainMetaData: {
        name: data.name,
        symbol: data.symbol,
        image: data.tokenImage,
        description: data.description,
        decimals: parseInt(decimals).toFixed(0),
      },
      offchainUri: data.offchainUri,
      amountToMint: toDecimalsBN(data.mintAmount, decimals ?? DEFAULT_DECIMALS),
    };
    setIsLoading(true);
    const deployParams = createDeployParams(params, data.offchainUri);
    const contractAddress = new ContractDeployer().addressForContract(deployParams);

    const isDeployed = await WalletConnection.isContractDeployed(contractAddress);

    if (isDeployed) {
      showNotification(
        <>
          Contract already deployed,
          <ReactRouterLink to={`${ROUTES.jetton}/${Address.normalize(contractAddress)}/`}>
            View contract
          </ReactRouterLink>
        </>,
        "warning",
      );
      setIsLoading(false);
      return;
    }

    try {
      const result = await jettonDeployController.createJetton(params, tonconnect, walletAddress);
      console.log(result, 'result')
      analytics.sendEvent(
        AnalyticsCategory.DEPLOYER_PAGE,
        AnalyticsAction.DEPLOY,
        contractAddress.toFriendly(),
      );

      navigate(`${ROUTES.jetton}/${Address.normalize(result)}`);
      setConAddress(Address.normalize(result))
      console.log(Address.normalize(result), 'Address.normalize(result)')
      // navigate("/management");

    } catch (err) {
      if (err instanceof Error) {
        showNotification(<>{err.message}</>, "error");
      }
    } finally {
      setIsLoading(false);
    }
  }


  const [opacity, setOpacity] = useState(false)
  const [opacity1, setOpacity1] = useState(false)
  const matches = useMediaQuery("(max-width:900px)");
  return (
    <Screen>
      <ScreenContent removeBackground>
        <Fade in>
          <Box mt={matches1 ? 5 : 20}>

            <FormWrapper>
              <SubHeadingWrapper>
                <Form
                  isLoading={isLoading}
                  submitText={t("Deploy")}
                  onSubmit={deployContract}
                  inputs={formSpec}
                />
              </SubHeadingWrapper>
            </FormWrapper>
            <SubHeadingWrapper1>
              <div style={{ cursor: "pointer" }} onClick={() => { setOpacity(!opacity); if (opacity1) { setOpacity1(!opacity1) } }}>
                <span>
                  {t("view video tutorial")}
                </span>
                {opacity && (
                  <div>
                    <img src="/images/select.webp" alt="select" />
                  </div>
                )}

              </div>
              {opacity && (
                <>
                  {opacity1 &&
                  // <iframe
                  //   title="Inline Frame Example"
                  //   width="100%"
                  //   height={matches ? "200" : "450"}
                  //   style={{ border: "none", marginTop: "20px" }}
                  //   src="https://www.youtube.com/embed/O-lJQJFjF0A">

                  // </iframe>
                  <Iframe src={"https://www.youtube.com/embed/O-lJQJFjF0A"} width={"100%"} height={matches ? "200" : "450"}></Iframe>
                  }
                  {!opacity1 && <ul style={{ cursor: "pointer" }} onClick={() => setOpacity1(!opacity1)}>
                    <img src="/images/ton.png" alt="ton链发币教程" />
                  </ul>}
                </>
              )}
            </SubHeadingWrapper1>
          </Box>
        </Fade>
      </ScreenContent>
      {/* {conAddress && (<Notification2 messageList={["请记住你的代币地址，进入查询代币信息需要使用代币地址搜索",conAddress]} title={"创建成功"} notType={"MarketValueOfWallt"}></Notification2>)} */}
    </Screen>
  );
}

export { DeployerPage };

const Spacer = () => {
  return <aside style={{ height: 25 }}></aside>;
};

function Description() {
  return null;
}
